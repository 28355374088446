<template>
  <div>
    <v-form ref="form" v-model="isValid">
      <div class="field-title">Product Price *</div>
      <v-text-field
        v-model="link.amount"
        :disabled="paymentLinkId"
        label="Enter product price"
        type="number"
        outlined
        single-line
        required
      ></v-text-field>

      <div class="field-title">Link Validity *</div>
      <v-radio-group
        :disabled="paymentLinkId"
        v-model="link.linkExpiryType"
        row
      >
        <v-radio
          label="Lifetime"
          value="lifetime"
          color="primary"
          class="radio-btn"
        ></v-radio>
        <v-radio
          label="Custom"
          value="custom"
          color="primary"
          class="radio-btn"
        ></v-radio>
      </v-radio-group>

      <div
        v-if="link.linkExpiryType === 'custom'"
        class="date-picker__container"
        :class="{ mb__2: !validDatetime, mb__12: validDatetime }"
      >
        <date-picker
          class="date-picker__container--item"
          style="border: 1.5px solid gray;
              border-radius: 5px;
              height: 54px;
              width: 100%;
              margin-bottom: 24px;"
          v-model="link.linkExpiryDate"
          :disabled-date="
            (date) => date < new Date(new Date().valueOf() - 1000 * 3600 * 24)
          "
          placeholder="Select a date & time"
          type="datetime"
          valueType="format"
          :open.sync="open"
          @change="handleChange"
          format="YYYY-MM-DD hh:mm a"
          time-title-format="hh:mm a"
          :show-second="false"
        >
        </date-picker>
      </div>

      <div class="field-title">Stock Count</div>
      <v-text-field
        v-model="link.stockCount"
        label="Enter Stock Count"
        outlined
        single-line
        required
        :rules="[rules.digitOnly]"
      ></v-text-field>

      <div class="field-title">Product Name *</div>
      <v-text-field
        v-model="link.productName"
        :disabled="paymentLinkId"
        label="Enter the Product Name"
        outlined
        single-line
        required
      >
        <template v-slot></template>
      </v-text-field>

      <div class="field-title">Product Description</div>
      <v-text-field
        v-model="link.description"
        :disabled="paymentLinkId"
        label="Enter the Product Description"
        outlined
        single-line
        required
      ></v-text-field>

      <div class="field-title">Product Image</div>
      <div
        v-if="!link.image"
        class="upload-box"
        @click="manuallyOpenFileSelector"
      >
        <!-- No image has been selected -->
        Click to add image
      </div>
      <div v-else class="upload-box" @click="manuallyOpenFileSelector">
        <!-- An image has been selected -->
        <img class="image-size" :src="link.image || url" />
      </div>
      <v-file-input
        :disabled="paymentLinkId"
        id="fileSelector"
        v-model="image"
        ref="fileSelector"
        show-size
        accept="image/png, image/jpg"
        class="d-none"
        @change="previewImage"
      >
      </v-file-input>
      <div
        class="error_div"
        v-if="imageTypeError || imageSize > imageSizeThresholdInBytes"
      >
        <span class="size_error_text">
          Image size should be less 250KB.
        </span>
      </div>
      <br/>

      <div style="color: grey; border: 1px solid grey; border-radius: 10px; padding: 10px;">
        <div>
          Collect Customer Information
        </div>

        <br/>
        <template
                class="reference__container"
                v-for="(reference, index) in link.references"
        >
        <span :key="index">
          <div class="field-title">{{reference.fieldName}}</div>

          <span class="opinion__container">
            <v-checkbox
                    :disabled="paymentLinkId"
                    label="Mandatory field"
                    color="primary"
                    v-model="reference.required"
            ></v-checkbox>
            <v-icon
                    v-if="!paymentLinkId"
                    color="primary"
                    @click="removeReferenceField(index)"
            >
              mdi-close
            </v-icon>
          </span>
        </span>
        </template>
        <div style="display: flex; justify-content: center; margin-bottom: 20px;">
          <additional-field
                  :disabled="paymentLinkId !== null"
                  :additionalFieldCount="link.references.length"
                  @addReference="addReference"
          ></additional-field>
        </div>
      </div>
      <br />

    </v-form>

    <v-btn
      v-if="!paymentLinkId"
      color="primary"
      width="100%"
      @click="createPaymentLink"
      >Create</v-btn
    >
    <v-btn
      v-else
      color="primary"
      width="100%"
      @click="editPaymentLink"
      :disabled="!isValid"
      >Edit</v-btn
    >
  </div>
</template>

<script>
import AdditionalField from "@/components/paymentLinks/components/AdditionalField.vue";
import { INTEGER_ONLY } from "cpp-js-util/CppUtil";

export default {
  name: "PaymentLinkForm",
  components: { AdditionalField },
  props: ["paymentLinkId", "link"],
  data() {
    return {
      imageFile: null,
      image: null,
      mimeType: null,
      imageSize: null,
      fileExtension: null,
      isValid: false,
      rules: {
        required: (v) => !!v || "Value is required",
        maxAmount: (v) =>
          (v && v.length < 10) || "Amount must be less than 10 digits",
        minAmount: (v) => v > 0 || "Amount must be greater than 0",
        digitOnly: (v) =>
          v.length === 0 || INTEGER_ONLY.test(v) || "Positive integers only",
      },
    };
  },
  methods: {
    addReference(additionalField) {
      console.log("i was triggered", additionalField);
      this.link.references.push({ ...additionalField });
    },
    removeReferenceField(index) {
      this.link.references.splice(index, 1);
    },
    manuallyOpenFileSelector() {
      let el = this.$refs.fileSelector.$refs.input;
      el.click();
    },
    async createPaymentLink() {
      this.$feedback.showLoading();
      var formData = new FormData();

      if (this.image) {
        formData.append("image", this.imageFile);
        formData.append("fileExtension", this.fileExtension);
        formData.append("mimeType", this.mimeType);
      }
      formData.append("productName", this.link.productName);
      formData.append("linkExpiryType", this.link.linkExpiryType);
      if (this.link.linkExpiryType === "custom") {
        formData.append("linkExpiryDate", this.link.linkExpiryDate);
      }
      formData.append("amount", this.link.amount);
      formData.append("description", this.link.description);
      formData.append("references", JSON.stringify(this.link.references));
      formData.append("stockCount", this.link.stockCount);
      try {
        let res = await this.$cppClient.post(
          "/payment-link/create",
          formData,
          null,
          true
        );
        let id = res.data.paymentLinkId;
        this.$router.push({ path: `create/success/${id}` });
        this.$feedback.hideLoading();
      } catch ({ response }) {
        this.$feedback.hideLoading();
        this.$feedback.showFailed(response);
      } finally {
        this.clearForm();
      }
    },
    async editPaymentLink() {
      this.$feedback.showLoading();
      let requestBody = {
        paymentLinkId: this.paymentLinkId,
        stockCount: this.link.stockCount === "" ? null : this.link.stockCount,
      };

      try {
        await this.$cppClient.post("/payment-link/update", requestBody);
        this.$router.push({ path: `success/${this.paymentLinkId}` });
        this.$feedback.hideLoading();
      } catch ({ response }) {
        this.$feedback.hideLoading();
        this.$feedback.showFailed(response);
      } finally {
        this.clearForm();
      }
    },
    previewImage() {
      this.imageFile = event.target.files[0];
      this.imageSize = event.target.files[0].size;
      this.url = URL.createObjectURL(this.image);
      this.mimeType = this.image.type;

      let image = new Image();
      image.src = URL.createObjectURL(event.target.files[0]);

      let app = this;
      //Validate the File Height and Width.
      image.onload = function() {
        app.logoHeight = this.height;
        app.logoWidth = this.width;
      };
      this.fileExtension = this.image.name
        .split(".")
        .pop()
        .toLowerCase();
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.image = reader.result.split(",").pop();
      };
    },
  },
};
</script>

<style scoped lang="scss">
$mobileW: 500px;
$mobileSm: 395px;
$mobileM: 565px;

.field-title {
  font-size: 18px;
  margin-bottom: 10px;
  color: #464646;
  font-weight: 500;

  @media (max-width: $mobileM) {
    font-size: 14px;
  }
}

.radio-btn {
  border: 1px solid #e2136e;
  border-radius: 4px;
  padding: 5px 10px;
  height: 40px;
  width: 150px;

  @media (max-width: $mobileM) {
    width: 140px;
    margin-right: 10px !important;
  }
}

.upload-box {
  border-radius: 4px;
  border: 1px dashed #9a9a9a;
  background: #f3f3f3;
  display: flex;
  width: 526px;
  padding: 2px 24px 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  cursor: pointer;

  @media (max-width: $mobileM) {
    width: 100%;
  }
}
.error_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;
}
.size_error_text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  padding-right: 15px;
  padding-left: 15px;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #d0021b;
}

.image-size {
  max-width: 524px;
  max-height: 226px;

  @media (max-width: $mobileM) {
    max-width: 100%;
    max-height: 150px;
  }
}

.input-field {
  width: 310px !important;
  margin-bottom: 10px;

  @media (max-width: $mobileSm) {
    width: 290px !important;
  }
}

.reference__container {
  display: flex;
  align-items: center;

  &--item {
    margin-bottom: 0 !important;
  }
}

.opinion__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
