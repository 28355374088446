<template>
  <v-card
    elevation="0"
    width="606"
    style="margin: 100px auto auto auto; background-color: #fff; padding: 40px"
    class="card-container"
  >
    <div class="card-title">
      <v-icon class="back-arrow" large @click="$router.go(-1)"
        >mdi-chevron-left</v-icon
      >
      Edit Payment Link
    </div>
    <PaymentLinkForm
      :link="paymentLink"
      :paymentLinkId="paymentLink.paymentLinkId"
    ></PaymentLinkForm>
  </v-card>
</template>

<script>
import PaymentLinkForm from "./components/PaymentLinkForm.vue";

export default {
  name: "EditPaymentLink",
  components: { PaymentLinkForm },
  data() {
    return {
      paymentLink: null,
    };
  },
  mounted() {
    let id = this.$route.params.id;
    this.getPaymentLink(id);
  },
  methods: {
    manuallyOpenFileSelector() {
      let el = this.$refs.fileSelector.$refs.input;
      el.click();
    },
    async getPaymentLink(paymentLinkId) {
      this.$feedback.showLoading();
      try {
        let res = await this.$cppClient.post("/payment-link/details", {
          paymentLinkId,
        });
        this.paymentLink = res.data;
        this.paymentLink.linkExpiryType = this.paymentLink.lifetime
          ? "lifetime"
          : "custom";

        this.$feedback.hideLoading();
      } catch ({ response }) {
        this.$feedback.hideLoading();
        this.$feedback.showFailed(response);
      } finally {
        this.clearForm();
      }
    },
  },
};
</script>

<style scoped lang="scss">
$mobileW: 500px;
$mobileSm: 395px;
$mobileM: 565px;

.card-container {
  margin: 100px auto auto auto;
  background-color: #fff;
  padding: 40px;

  @media (max-width: $mobileM) {
    width: 95% !important;
    padding: 20px !important;
  }
}

.back-arrow {
  display: none;

  @media (max-width: $mobileM) {
    display: inline-block;
  }
}
.card-title {
  font-size: 24px;
  margin-bottom: 24px;
  color: #464646;
  font-weight: 600;

  @media (max-width: $mobileM) {
    font-size: 18px;
    display: flex;
    align-items: center;
  }
}
</style>
